import React from "react"
import { Container, Grid, Icon, Image, Modal } from "semantic-ui-react"

import thumbnail from "../../images/thumb.png"

export default function Community() {
  return (
    <div id="community" className="section community">
      <div className="border"></div>
      <Container>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Modal
                trigger={
                  <div className="gameplay-trailer">
                    <div className="gallery-container">
                      <div className="gallery">
                        <Image src={thumbnail} />
                      </div>
                      <div className="fader"></div>
                      <div className="play-frame">
                        <button aria-label="play" type="button"></button>
                      </div>
                      <div className="frame"></div>
                    </div>
                    <p className="thumb-description">Gameplay trailer</p>
                  </div>
                }
                centered={false}
              >
                <Modal.Content image>
                  <div className="gallery-container">
                    <div className="gallery">
                      <iframe
                        title="reset hype video"
                        width="878"
                        height="457"
                        src="https://www.youtube.com/embed/Du_2_Wp5AEk?autoplay=1&rel=0&iv_load_policy=3&color=white&autohide=1"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="frame"></div>
                  </div>
                </Modal.Content>
              </Modal>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <h2 className="serif">Join the online community</h2>
              <p>The heart of SlashDiablo is our community.</p>
              <p>
                Everything from game patch feedback to bolstering our Moderation
                team - all comes from within our playerbase. Our discord server
                is busy with our in-game chat relay and friendly banter while
                our subreddit is great to see item finds, trades and community
                updates.
              </p>
              <Grid className="external-communities" columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <a
                      className="comm-link"
                      href="https://discord.gg/0Xeb4YQwnUVhxisY"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Join us on Discord"
                    >
                      <Icon
                        className="discord-icon"
                        name="discord"
                        size="huge"
                      />
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a
                      className="comm-link"
                      href="https://old.reddit.com/r/slashdiablo"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Join us on Reddit"
                    >
                      <Icon
                        className="reddit-icon"
                        name="reddit square"
                        size="huge"
                      />
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a
                      className="comm-link"
                      href="https://patreon.com/slashgaming"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Support us on Patreon"
                    >
                      <Icon
                        className="patreon-icon"
                        name="patreon"
                        size="huge"
                      />
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}
