import React from "react"
import { Divider, Flag, Icon, Grid, List } from "semantic-ui-react"

export default function GameServers() {
  return (
    <div id="game-servers" className="section game-servers">
      <div className="border"></div>
      <Grid container stackable>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Divider horizontal>
              <h2>Multiple game servers</h2>
            </Divider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <p>
              Our localised gameservers allow for a quality experience by
              providing a low-ping connection to a server near you.
            </p>
            <p>
              To ultilise the system, simply add gsX (gs1, gs2, g3, etc.) into
              the game description field when making a game. You will receieve a
              message when you enter the game to make sure it has been made on
              the correct gameserver.
            </p>
            <p>
              If you are using our maphack then you will be able to configure it
              to remember your selection and it will also display what
              gameserver a cetain game is made on when viewing it from the 'Join
              Game' screen.
            </p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <List inverted>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">New york</List.Header>
                  <List.Description>Name: gs1</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">Chicago</List.Header>
                  <List.Description>Name: gs2</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">Silicon valley</List.Header>
                  <List.Description>Name: gs3</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">Seattle</List.Header>
                  <List.Description>Name: gs4</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">Dallas</List.Header>
                  <List.Description>Name: gs5</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="us" />
                </div>
                <List.Content>
                  <List.Header as="a">Miami</List.Header>
                  <List.Description>Name: gs6</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="nl" />
                </div>
                <List.Content>
                  <List.Header as="a">Amsterdam</List.Header>
                  <List.Description>Name: gs7</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Icon name="check circle" color="green" />
                </List.Content>
                <div className="ui avatar image ">
                  <Flag name="au" />
                </div>
                <List.Content>
                  <List.Header as="a">Sydney</List.Header>
                  <List.Description>Name: gs8</List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
