import React from "react"
import { Container, Grid, Divider } from "semantic-ui-react"

import DiabloButton from "../DiabloButton"
//import Particle from "./Particle"
import LadderReset from "../LadderReset"

export default function Splash() {
  // Particles.
  // var particles = []
  // for (var i = 0; i < 40; i++) {
  //   particles.push(<Particle key={"particle-" + i} />)
  // }

  return (
    <div id="home" className="section splash">
      {/* {particles} */}
      <Container text textAlign="center">
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h1>Slashdiablo</h1>
              <LadderReset reset="2021-11-05T23:00:00.000+00:00" />
              <Divider horizontal>
                <h3>Diablo II Community server</h3>
              </Divider>
              <p>
                SlashDiablo is a privately operated, community fed, vanilla-like
                Diablo II Private Server. We host multiple gameservers throughout the world for low-ping
                gameplay and a suite of quality of life tools.
              </p>
              <DiabloButton content="PLAY NOW" linkTo="/get-started" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}
