import React from "react"
import { Container, Divider, Grid, Icon } from "semantic-ui-react"

export default function Features() {
  return (
    <div id="features" className="section features">
      <div className="border"></div>
      <Container>
        <Divider horizontal>
          <h2>Features</h2>
        </Divider>
        <Grid relaxed="very" columns={3} stackable>
          <Grid.Column>
            <h3 className="serif">Global Chat</h3>
            <p>
              Our in-game global chat system is the heart of trading while playing SlashDiablo.
              It is also relayed to the discord server to make sure you haven't missed a trade or chat.
              To partake in this system, simply type <em>{"//sub chat"}</em> while in game.
            </p>
          </Grid.Column>
          <Grid.Column>
            <h3 className="serif">Gameplay Updates</h3>
            <p>
              SlashDiablo has updated a few minor gameplay changes to keep things fresh for returning players
              but keeping as true to the same vanilla Diablo II we all long for. We've made it easier to accquire the
              infamous annihilus charm, changed how NoDrop works and enabled the runeword 'Plague' that is found in the patch files.

              Read about all our {" "}
              <a
                rel="noopener noreferrer"
                href="https://www.reddit.com/r/slashdiablo/comments/51xtii/eli5_nodrop/"
                target="_blank"
              >
                gameplay updates here <Icon name="external alternate" />
              </a>
            </p>
          </Grid.Column>
          <Grid.Column>
            <h3 className="serif">Multiboxing</h3>
            <p>
              Multiboxing is allowed with 4 boxes per IP and is easily
              obtainable by using the SlashDiablo launcher to setup your game,
              or the setup guide on Reddit to download the appropiate files
              manually.
            </p>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}
