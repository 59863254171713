import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import SplashSection from "../components/Sections/Splash"
import CommunitySection from "../components/Sections/Community"
import ModsSection from "../components/Sections/Mods"
import FeaturesSection from "../components/Sections/Features"
import GameServersSection from "../components/Sections/GameServers"
import Header from "../components/Header"

import "../styles/index.less"

const IndexPage = () => (
  <Layout>
    <SEO title="Slashdiablo" />
    <Header top={827} active="home" />
    <SplashSection />
    <CommunitySection />
    <FeaturesSection />
    <ModsSection />
    <GameServersSection />
  </Layout>
)

export default IndexPage
