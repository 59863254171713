import React, { useEffect, useState } from "react";
import { Divider } from "semantic-ui-react"

import "./style.less"

const LadderReset = ({ reset }) => {
  // tick will calcualte the time left to the given date time.
  const tick = () => {
    // calculate difference in time to expiration.
    const diff = +new Date(reset) - +new Date();

    if (diff > 0) {
      return {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
        expired: false
      }
    }

    // date time has expired.
    return {
      expired: true
    }
  }

  // timer state includes the normalized time data.
  const [timer, setTimer] = useState(tick());

  // useEffect runs once the component loads, setting state
  // in the tick func will trigger it again and clearing the timeout.
  useEffect(() => {
    // set a timeout to tick every sec.
    let counter = setTimeout(() => {
      var t = tick()
      // Only update the timer if it has not expired yet.
      if (!t.expired) {
        setTimer(t)
      }
    }, 1000);
    // clean up func run at return.
    return () => clearTimeout(counter);
  });

  if (timer.expired) {
    return <></>;
  }

  // timer hasn't expired yet, so render count down.
  return (
    <div className="countdown-box">
      <Divider horizontal>
        <h3>Ladder reset</h3>
      </Divider>
      <div className="countdown-col">
        <p>{timer.days}</p>
        <p>Days</p>
      </div>
      <div className="countdown-col">
        <p>{timer.hours}</p>
        <p>Hours</p>
      </div>
      <div className="countdown-col">
        <p>{timer.minutes}</p>
        <p>Minutes</p>
      </div>
      <div className="countdown-col">
        <p>{timer.seconds}</p>
        <p>Seconds</p>
      </div>
    </div>
  );
}

export default LadderReset
