import React from "react"
import { Container, Grid, Divider, Icon, Segment } from "semantic-ui-react"

import ImageModal from "../ImageModal"
import hd from "../../images/screenshots/hd.png"
import armory from "../../images/screenshots/armory.png"

export default function Mods() {
  return (
    <div id="mods" className="section mods">
      <div className="border"></div>
      <Container>
        <Divider horizontal>
          <h2>Available Tools</h2>
        </Divider>
        <Segment>
          <Grid columns={2} stackable>
            <Grid.Column>
              <div className="gallery-container">
                <div className="gallery">
                  <ImageModal img={hd} />
                </div>
                <div className="frame"></div>
              </div>
              <h3 className="serif">Maphack + HD mod</h3>
              <span>
                <Icon name="github square" size="large"></Icon>
                <a
                  href="https://github.com/planqi/slashdiablo-maphack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source <Icon name="external alternate" />
                </a>
              </span>
              <p>
                Our custom built maphack gives a wave of quality-of-life features that
                will be hard to live without once you've tried it. Map reveal, sequental gamename and password auto-fill, auto-party/loot, highly configurable loot filter with drop notifications and, of course, widescreen/higher resolution support. 1.9.9 has just been released - check out the latest pinned reddit post for a full set of features!
              </p>
            </Grid.Column>
            <Grid.Column>
              <div className="gallery-container">
                <div className="gallery">
                  <ImageModal img={armory} />
                </div>
                <div className="frame"></div>
              </div>
              <h3 className="serif">Armory</h3>
              <span>
                <a
                  href="https://armory.slashdiablo.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Armory website <Icon name="external alternate" />
                </a>
              </span>
              <p>
                An amazing resource that has modernised Diablo II and how we trade on SlashDiablo.
              </p>
              <p>
                This custom built website allows you to see all your characters statistics and gear - giving our players the ability
                to show-off their build or link directly to their item mules for trading.
              </p>
            </Grid.Column>
          </Grid>
          <Divider vertical>
            <span>And</span>
          </Divider>
        </Segment>
      </Container>
    </div>
  )
}
