import React from "react"

import "./style.less"

const DiabloButton = ({ content, linkTo }) => (
  <div className="heading">
    <a className="btn" href={linkTo}>
      {content}
    </a>
  </div>
)

export default DiabloButton
